import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import IndexPage from '../views/IndexPage.vue'
import TabsPage from '../views/TabsPage.vue'


const routes: Array<RouteRecordRaw> = [
  { 
    path: '/',
    redirect: 'tabs/mensajes',
    meta:{requiredAuth:true}
  },
  { 
    component: TabsPage,
    path: '/',
    children: [
      {
        path: '/entidad/:entidad_id',
        name: 'Ficha de la entidad',
        component: () => import('@/views/EntidadPage.vue'),
        meta:{requiredAuth:true}
      },
    ]
  },

  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/Organizacion/',
    component: TabsPage,
    children: [
      {
        path: 'Mensajes',
        name: 'Mis Mensajes',
        component: () => import('@/components/Mensaje/MensajeList.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'Tasks/:user_id?',
        name: 'Tasks',
        component: () => import('@/components/Task/TaskList.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'procesos',
        name: 'Procesos',
        component: () => import('@/components/Proceso/ListComponent.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'proceso/:proceso_id',
        name: 'Proceso',
        component: () => import('@/components/Proceso/ItemComponent.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'Tiempos',
        name: 'Tiempos',
        component: () => import('@/components/Tiempo/TiempoCrud.vue'),
        meta:{requiredAuth:true}
      },
    ]
  },
  {
    path: '/settings/',
    component: TabsPage,
    children: [
      {
        path: 'log',
        name: 'Log',
        component: () => import('@/components/Settings/LogList.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'config',
        component: () => import('@/views/Settings/ConfigPage.vue'),
        name: 'Configuración',
        meta:{requiredAuth:true}
      },
    ]
  },
  {
    path: '/financiero',
    component: TabsPage,
    children: [
      {
        path: '',
        name: 'financiero',
        component: () => import('@/views/FinancieroPage.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: '/financiero/proveedores',
        name: 'Proveedores',
        component: () => import('@/views/ProveedoresPage.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: '/financiero/transacciones',
        name: 'Transacciones',
        component: () => import('@/views/TransactionsPage.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: '/financiero/saldos',
        name: 'Saldos',
        component: () => import('@/views/SaldosPage.vue'),
        meta:{requiredAuth:true}
      },

    ]
  },
  {
    path: '/clases',
    component: TabsPage,
    children: [
      {
        path: 'uploadVideo/:sesion_id',
        name: 'uploadVideo',
        component: () => import('@/views/Clase/UploadVideoPage.vue'),
        meta:{requiredAuth:true}
      },
    ]
  },
  {
    path: '/Tools/',
    component: IndexPage,
    children: [
      {
        path: 'CountAsistentes',
        name: 'CountAsistentes',
        component: () => import('@/components/Tools/CountAsistentes.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'Cart',
        name: 'Pagar actividad',
        component: () => import('@/views/Clase/CartPage.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'sesion/:sesion_id',
        name: 'Sesion de clase',
        component: () => import('@/views/Clase/SesionPage.vue'),
        meta:{requiredAuth:true}
      },
    ]
  },
  {
    path: '/galidancia/',
    component: TabsPage,
    children: [
      {
        path: 'sesiones',
        name: 'sesiones',
        component: () => import('@/components/Galidancia/CalendarSesiones.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'clases',
        name: 'clases',
        component: () => import('@/components/Galidancia/ClaseCrud.vue'),
        meta:{requiredAuth:true}
      },

    ],
  },

  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: 'mensajes',
        name: 'Mis mensajes',
        component: () => import('@/components/Mensaje/MensajeList.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'asientos',
        name: 'Mis pagos',
        component: () => import('@/views/AsientosPage.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'clases',
        component: () => import('@/views/ClasesPage.vue'),
        name: 'Mis clases',
        meta:{requiredAuth:true}
      },
      {
        path: 'asistencias/:clase_id?',
        component: () => import('@/views/Clase/AsistenciasPage.vue'),
        name: 'Mis asistencias',
        meta:{requiredAuth:true}
      },
      {
        path: 'confirmAsistencia/:sesion_id',
        component: () => import('@/components/Clase/confirmAsistencia.vue'),
        name: 'Confirmar asistencia',
        meta:{requiredAuth:true}
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue'),
        name: 'Galidancia',
        meta:{requiredAuth:true}
      },
      {
        path: 'galidancia',
        component: () => import('@/views/GalidanciaPage.vue'),
        name: 'Galidancia',
        meta:{requiredAuth:true}
      },
      {
        path: 'necesidades',
        component: () => import('@/views/Galidancia/NecesidadesView.vue'),
        name: 'Deteccion de Necesidades',
        meta:{requiredAuth:true}
      },
      {
        path: 'cuadromando',
        component: () => import('@/views/Galidancia/CuadroDeMando.vue'),
        name: 'cuadro de mando',
        meta:{requiredAuth:true}
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
